var app = { tacUrl: "" };
document.addEventListener('DOMContentLoaded', function () {
    var availableInChineseBanner = document.querySelector('.available-in-chinese-msg');
    if (availableInChineseBanner !== null && availableInChineseBanner.length > 0) {
        if (document.querySelector('.CybotCookiebotDialogActive') !== null) {
            window.addEventListener('CookiebotOnAccept', function (e) {
                availableInChineseBanner.classList.add('show');
            }, false);
            window.addEventListener('CookiebotOnDecline', function (e) {
                availableInChineseBanner.classList.add('show');
            }, false);
        } else {
            setTimeout(function () {
                availableInChineseBanner.classList.add('show');
            }, 2000);
        }
    }
});
document.addEventListener('DOMContentLoaded', function() {
    var blockStripeArea = document.querySelector('.block-stripes');

    if (blockStripeArea) {
        var stripeBlocks = blockStripeArea.querySelectorAll('.cta-page-block');
        var indexStripeBlocksMobile = 0;
        var indexStripeBlocksDesktop = 0;
        var validItemsDesktop = [];

        for (var i = 0; i < stripeBlocks.length; i++) {
            var item = stripeBlocks[i].parentElement;
            if (!item.closest('.article-content')) {
                indexStripeBlocksMobile++;
                item.classList.add('stripe-mobile');
                if (indexStripeBlocksMobile % 2 !== 0) {
                    item.classList.add('stripe-mobile--odd');
                } else {
                    item.classList.add('stripe-mobile--even');
                }
            }
        }

        for (var i = 0; i < stripeBlocks.length; i++) {
            var item = stripeBlocks[i];
            if (!item.closest('.article-content')) {
                if (item.parentElement.classList.contains('col-lg-6')) {
                    validItemsDesktop.push(item.closest('.container'));
                } else {
                    validItemsDesktop.push(item.parentElement);
                }
            }
        }

        for (var i = 0; i < validItemsDesktop.length; i++) {
            var vItem = validItemsDesktop[i];
            if (!item.closest('.article-content')) {
                indexStripeBlocksDesktop++;
                vItem.classList.add('stripe');
                if (indexStripeBlocksDesktop % 2 !== 0) {
                    vItem.classList.add('stripe--odd');
                } else {
                    vItem.classList.add('stripe--even');
                }
            }
        }
    }
});
$(function () {

    var termsAndConditionsUrl = app.tacUrl;
    if (!termsAndConditionsUrl) {
        return;
    }

    var $form = $("form[data-f-metadata='document-request-form']");
    if (!$form.length) {
        return;
    }

    var $input = $form.find("input[value='accept-terms']");
    if (!$input.length) {
        return;
    }

    var template = $("#terms-and-conditions-template").html();

    var data = { url: termsAndConditionsUrl };
    var element = Mustache.render(template, data);

    var $label = $input.parent();
    $label.contents().last().remove();
    $label.append(element);

});
document.addEventListener('DOMContentLoaded', function () {
    $('.document-row').on('click', function () {
        /* Not too happy with this, but seems to be the easiest way to achieve this */
        window.location = $(this).find('[data-download-link]').attr('href');
        return false;
    });
});
document.addEventListener("DOMContentLoaded", function (event) {
    "use strict";

    function resizeTextAreas() {
        $('[data-max-text-lines]').each(function () {
            var $txtContainer = $(this);

            var currentHeight = $txtContainer.height();
            var lineHeight = parseFloat($txtContainer.css('line-height'));
            var numberOfLines = parseFloat($txtContainer.attr('data-max-text-lines'));
            var desiredHeight = numberOfLines * (lineHeight - 1);

            $txtContainer.css('height', desiredHeight);

            if (currentHeight > desiredHeight) {
                $txtContainer        
                    .css('overflow', 'hidden')
                    .attr('title', $txtContainer.text());
            }
        });
    }

    $(window).resize(function() {
        resizeTextAreas();
    });

    resizeTextAreas();
});
function headerElementClickTracking(target, category) {
    dataLayer.push({
        event: "click",
        params: {
            name: "navigation",
            location: "menu",
            target: target,
            page_category: category
        }, 
    });
}

document.addEventListener('DOMContentLoaded', function () {
    var nextButton = document.querySelector('.Form__NavigationBar__Action.btnNext');
    var currentStepElement = document.querySelector('.Form__NavigationBar__ProgressBar__CurrentStep');
    var formTitleElement = document.querySelector('li[data-current-page-breadcrumb]');

    if (nextButton && currentStepElement && formTitleElement) {
        nextButton.addEventListener('click', function () {
            var currentStepNumber = currentStepElement.textContent;
            var formTitle = formTitleElement.textContent;
            dataLayer.push({
                event: "support_form",
                validation_form: {
                    form_name: formTitle,
                    step_index: currentStepNumber
                },
            });
        });
    }
});

document.addEventListener('DOMContentLoaded', function () {
    var submitButton = document.querySelector('.Form__Element.FormSubmitButton');
    var currentStepElement = document.querySelector('.Form__NavigationBar__ProgressBar__CurrentStep');
    var formTitleElement = document.querySelector('li[data-current-page-breadcrumb]');

    if (submitButton && currentStepElement && formTitleElement) {
        submitButton.addEventListener('click', function () {
            var currentStepNumber = currentStepElement.textContent;
            var formTitle = formTitleElement.textContent;
            dataLayer.push({
                event: "support_form",
                validation_form: {
                    form_name: formTitle,
                    step_index: currentStepNumber,
                    form_submitted: "TRUE"
                },
            });
        });
    }
});
document.addEventListener('DOMContentLoaded', function () {
    function equalizeHeights() {
        var groupHeights = {};

        $('[data-eq-height-group]').each(function () {
            var $element = $(this);

            $element.css('height', '');
            
            var groupName = $element.attr('data-eq-height-group');
            var height = $element.height();

            if (!groupHeights[groupName]) {
                groupHeights[groupName] = 0;
            }

            groupHeights[groupName] = height > groupHeights[groupName] ? height : groupHeights[groupName];
        }).each(function () {
            var $element = $(this);
            var groupName = $element.attr('data-eq-height-group');
            $element.css('height', groupHeights[groupName] + 'px');
        });
    }

    $(window).on('resize', function() {
        equalizeHeights();
    });

    equalizeHeights();
});
document.addEventListener('DOMContentLoaded', function () {
    var heroBlocks = document.querySelectorAll('.hero-block');

    for (var i = 0; i < heroBlocks.length; i++) {
        var firstHeroBlock = heroBlocks[0];

        firstHeroBlock.classList.add('first-hero-block');
    }

    function heroFontSize() {
        for (var i = 0; i < heroBlocks.length; i++) {
            var heroBlock = heroBlocks[i];
            var heroBody = heroBlock.querySelector('.body');

            if (heroBody) {
                var numWords = heroBody.innerText.length;

                if ((numWords >= 1) && (numWords < 30)) {
                    heroBody.classList.add("font-size-xxl");
                }
                else if ((numWords >= 30) && (numWords < 40)) {
                    heroBody.classList.add("font-size-xl");
                }
                else if ((numWords >= 40) && (numWords < 55)) {
                    heroBody.classList.add("font-size-l");
                }
                else if ((numWords >= 55) && (numWords < 70)) {
                    heroBody.classList.add("font-size-n");
                }
                else {
                    heroBody.classList.add("font-size-s");
                }
            }
        }
    }

    heroFontSize();
});
(function() {
    var highlightNumbersBlocks = document.querySelectorAll('.block-highlight-numbers');
    for (var i = 0; i < highlightNumbersBlocks.length; i++) {
        var el = highlightNumbersBlocks[i];
        var container = el.closest('.row');
        if (!container.classList.contains('row-flex')) {
            container.classList.add('row-flex');
        }
    }
}());
//
// https://github.com/sukhoi1, 25 Jan 2016
// bicubic-img-interpolation v0.1.1 jQuery plugin

$.fn.bicubicImgInterpolation = function (settings) {

    var self = this;
    $(self).each(function () {
        if (this.tagName === "IMG") {
            var src = $(this).attr('src');
            var imgW = this.width;
            var imgH = this.height;
            var originalW = this.naturalWidth;
            var originalH = this.naturalHeight;
            if (originalW == imgW && originalH == imgH) {
                return;
            }
            var bgColor = getBackgroundColor($(this));
            $(this).after("<canvas style='display: none' width='" + imgW + "' height='" + imgH + "'></canvas>");
            var can = $(this).next()[0];
            var callback = drawHighResolutionImgThumbnail;
            drawCanvas(can, imgW * 6, imgH * 6, src, callback, this, settings.crossOrigin, bgColor);
        }
    });

    function getBackgroundColor($element) {
        if ($element == null) {
            return null;
        }
        if ($element.length == 0) {
            return null;
        }
        var color = $element.css("backgroundColor");
        if (color === "transparent") {
            return getBackgroundColor($element.parent());
        }
        return color;
    };

    function drawCanvas(can, imgW, imgH, src, callback, imgEl, crossOrigin, bgColor) {

        var ctx = can.getContext('2d');
        var img = new Image();
        if (crossOrigin) {
            img.setAttribute('crossOrigin', 'anonymous'); //tainted canvases may not be exported chrome, ie will also throw security error
        }

        var w = imgW;
        var h = imgH;

        img.onload = function () {

            // Step it down several times
            var can2 = document.createElement('canvas');
            can2.width = w;
            can2.height = h;
            var ctx2 = can2.getContext('2d');

            // Draw it at 1/2 size 3 times (step down three times)
            if (!!bgColor) {
                var fs = ctx2.fillStyle;
                ctx2.fillStyle = bgColor;
                ctx2.fillRect(0, 0, w / 2, h / 2);
                ctx2.fillStyle = fs;
            }
            ctx2.drawImage(img, 0, 0, w / 2, h / 2);
            ctx2.drawImage(can2, 0, 0, w / 2, h / 2, 0, 0, w / 4, h / 4);
            ctx2.drawImage(can2, 0, 0, w / 4, h / 4, 0, 0, w / 6, h / 6);
            ctx.drawImage(can2, 0, 0, w / 6, h / 6, 0, 0, w / 6, h / 6);
            if (callback) {
                callback(can, this.src, imgEl);
            }
        };

        img.src = src;
    };

    function drawHighResolutionImgThumbnail(can, attrSrc, imgEl) {
        $(imgEl).attr('src', can.toDataURL("image/png"));
        $(imgEl).attr('data-src', attrSrc);
    };
};

document.addEventListener("DOMContentLoaded", function (event) {
    "use strict";

    function detectIE() {
        var ua = window.navigator.userAgent;

        var msie = ua.indexOf('MSIE ');
        if (msie > 0) {
            // IE 10 or older => return version number
            return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
        }

        var trident = ua.indexOf('Trident/');
        if (trident > 0) {
            // IE 11 => return version number
            var rv = ua.indexOf('rv:');
            return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
        }

        // other browser
        return -1;
    };

    if (detectIE() < 9) {
        return;
    }

    var selectors = [".abstract img", ".body img"];

    selectors.forEach(function (sel) {
        $(sel).bicubicImgInterpolation({
            crossOrigin: 'anonymous' //otherwise browser security error is triggered
        });
    });

});
document.addEventListener('DOMContentLoaded', function() {
    var el = document.getElementById('langSelectorArea');
    if (el) {
        document.addEventListener('click', function(event) {
            var isClickInside = el.contains(event.target);
            if (!isClickInside && (document.querySelector('#langSelector').classList.contains('in') || document.querySelector('#langSelector').classList.contains('show'))) {
                document.querySelector('[data-target="#langSelector"]').click();
            }
        });
    }
});
document.addEventListener('DOMContentLoaded', function () {
    function loadVisibleBackgroundImages() {
        $('[data-lazy-background]').each(function () {
            if (elementIsInView(this)) {
                var backgroundImageUrl = $(this).attr('data-lazy-background');
                $(this).css('background-image', 'url("' + backgroundImageUrl + '")');
                $(this).removeAttr('data-lazy-background');
            }
        });
    }

    function elementIsInView(el) {
        var top = el.offsetTop;
        var left = el.offsetLeft;
        var width = el.offsetWidth;
        var height = el.offsetHeight;

        while (el.offsetParent) {
            el = el.offsetParent;
            top += el.offsetTop;
            left += el.offsetLeft;
        }

        return (
            top < (window.scrollY + window.innerHeight) &&
            left < (window.scrollX + window.innerWidth) &&
            (top + height) > window.scrollY &&
            (left + width) > window.scrollX
        );
    }

    $(document).on('scroll', function () {
        loadVisibleBackgroundImages();
    });

    loadVisibleBackgroundImages();
});
document.addEventListener('DOMContentLoaded', function () {
    document.body.classList.add('loaded');

    var toTopLink = document.querySelector('.to-top-of-page__link');
    var scrY = window.scrollY;

    window.addEventListener('scroll', function () {
        scrY = window.scrollY;
        document.documentElement.style.setProperty('--scroll-y', (scrY + 'px'));

        if (scrY > 500) {
            toTopLink.classList.add('shown');
        } else {
            toTopLink.classList.remove('shown');
        }
    });
});

document.addEventListener('DOMContentLoaded', function() {
    var caseStories = document.querySelectorAll('.magazine-layover-block');
    var focusableElements = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';

    caseStories.forEach(function (caseStory) {
        var caseStoryOpener = caseStory.querySelector('.case-story__opener');
        var caseStoryModal = caseStory.querySelector('.case-story__modal');
        var caseStoryModalInner = caseStory.querySelector('.case-story__inner');
        var caseStoryClose = caseStory.querySelector('.case-story__close');

        caseStoryOpener.addEventListener('click', function () {
            var firstFocusableElement = caseStoryModal.querySelectorAll(focusableElements)[0];
            var focusableContent = caseStoryModal.querySelectorAll(focusableElements);
            var lastFocusableElement = focusableContent[focusableContent.length - 1];

            caseStoryModal.style.display = 'block';
            setTimeout(function () {
                caseStoryModal.classList.add('shown');
                document.body.classList.add('modal-open');
                firstFocusableElement.focus();
            }, 100);

            // trap focus to elements inside modal
            caseStoryModal.addEventListener('keydown', function(e) {
                var keyCode = e.keyCode;
                var isTabPressed = e.key === 'Tab' || keyCode === 9;

                if (keyCode === 27) {
                    caseStoryClose.click();
                } else {
                    if (!isTabPressed) {
                        return;
                    }

                    if (e.shiftKey) {
                        if (document.activeElement === firstFocusableElement) {
                            lastFocusableElement.focus();
                            e.preventDefault();
                        }
                    } else {
                        if (document.activeElement === lastFocusableElement) {
                            firstFocusableElement.focus();
                            e.preventDefault();
                        }
                    }
                }
            });
        });

        caseStoryClose.addEventListener('click', function () {
            closeModal();
        });

        caseStoryModal.addEventListener('click', function (e) {
            if (!caseStoryModalInner.contains(e.target)) {
                closeModal();
            }
        });

        function closeModal () {
            caseStoryModal.classList.remove('shown');
            document.body.classList.remove('modal-open');
            caseStoryOpener.focus();
            setTimeout(function () {
                caseStoryModal.style.display = 'none';
            }, 600);
        }
    });
});
"use strict";

var map = {

    googleMap: null,
    markerClusterer: null,
    data: [],
    selectedLocationId: -1,

    markerIconURL: "",
    clusterIconPath: "",

    divisionFilters: [],
    locationTypeFilters: [],
    regionFilters: [],

    mapMarkers: [],

    dataForCounters: [],

    clusteredData: {},

    deferLoad: false,

    zoomLevelForLabels: 100,

    clusteredMarkers: [],

    drawTriggers: {
        base: "base.draw",
        zoomChanged: "zoom_changed",
        positionChanged: "position_changed",
        clusteringEnd: "clustering_end"
    },

    loadCallback: function () {

        if (this.data.length == 0) {
            this.deferLoad = true;
            return;
        }

        /// source: http://jsfiddle.net/6t3pyr94/
        google.maps.Marker.prototype.setLabel = function (label) {
            this.label = new MarkerLabel({
                map: this.map,
                marker: this,
                text: label,
                geoId: this.get("details").geoId
            });
            this.label.bindTo('position', this, 'position');
        };

        google.maps.Marker.prototype.removeLabel = function () {
            this.label.setMap(null);
            this.label = null;
        };

        google.maps.Marker.prototype.redrawLabel = function (reason) {
            var label = this.label;
            try {
                label.draw.call(label,reason);
            }
            catch (e) { }
        };

        google.maps.Marker.prototype.getLabelElement = function () {
            return this.label.getLabelElement();
        };

        var MarkerLabel = function (options) {
            this.setValues(options);
            this.htmlElement = document.createElement('div');
            this.htmlElement.className = 'map-marker-label';
            this.htmlElement.style.visibility = "hidden";
            var self = this;
            $(this.htmlElement).on("click", function () {
                google.maps.event.trigger(self.marker, "click");
            });
        };

        MarkerLabel.prototype = $.extend(new google.maps.OverlayView(), {
            onAdd: function () {
                this.getPanes().overlayImage.appendChild(this.htmlElement);
                var selfLabel = this;
                this.listeners = [
                    google.maps.event.addListener(this, 'position_changed', function () {
                        selfLabel.draw(map.drawTriggers.positionChanged);
                    })
                ];
            },
            draw: function (reason) {
                if (typeof reason === "undefined") {
                    reason = map.drawTriggers.base;
                }
                var shouldShow = this.map.getZoom() >= map.zoomLevelForLabels && !$(".location-details").length;
                var shouldDraw = (this.map.getZoom() >= (map.zoomLevelForLabels - 1)) && !$(".location-details").length;
                shouldDraw = shouldDraw && this.map.getBounds().contains(this.get('position'));
                var isClustered = false;
                if (shouldDraw) {
                    isClustered = map.isMarkerInCluster(this.get('marker'));
                }
                shouldDraw = shouldDraw && !isClustered;
                shouldShow = shouldShow && shouldDraw;
                if (shouldDraw) {
                    var text = String(this.get('text'));
                    var position = this.getProjection().fromLatLngToDivPixel(this.get('position'));
                    this.htmlElement.innerHTML = text;
                    var $el = $(this.htmlElement);
                    var left = (position.x + 20);
                    var top = (position.y - (($el.height() / 2) + 25));
                    this.htmlElement.style.left = left + 'px';
                    this.htmlElement.style.top = top + 'px';
                }
                if (shouldShow) {
                    this.htmlElement.style.visibility = "visible";
                } else {
                    this.htmlElement.style.visibility = "hidden";
                }
            },
            getLabelElement: function () {
                return this.htmlElement;
            }
        });
        ///

        var mapElement = $("#map");

        var mapStyles = [{ "elementType": "geometry", "stylers": [{ "color": "#f5f5f5" }] }, { "elementType": "labels.icon", "stylers": [{ "visibility": "off" }] }, { "elementType": "labels.text.fill", "stylers": [{ "color": "#616161" }] }, { "elementType": "labels.text.stroke", "stylers": [{ "color": "#f5f5f5" }] }, { "featureType": "administrative.land_parcel", "elementType": "labels.text.fill", "stylers": [{ "color": "#bdbdbd" }] }, { "featureType": "poi", "elementType": "geometry", "stylers": [{ "color": "#eeeeee" }] }, { "featureType": "poi", "elementType": "labels.text.fill", "stylers": [{ "color": "#757575" }] }, { "featureType": "poi.park", "elementType": "geometry", "stylers": [{ "color": "#e5e5e5" }] }, { "featureType": "poi.park", "elementType": "labels.text.fill", "stylers": [{ "color": "#9e9e9e" }] }, { "featureType": "road", "elementType": "geometry", "stylers": [{ "color": "#ffffff" }] }, { "featureType": "road.arterial", "elementType": "labels.text.fill", "stylers": [{ "color": "#757575" }] }, { "featureType": "road.highway", "elementType": "geometry", "stylers": [{ "color": "#dadada" }] }, { "featureType": "road.highway", "elementType": "labels.text.fill", "stylers": [{ "color": "#616161" }] }, { "featureType": "road.local", "elementType": "labels.text.fill", "stylers": [{ "color": "#9e9e9e" }] }, { "featureType": "transit.line", "elementType": "geometry", "stylers": [{ "color": "#e5e5e5" }] }, { "featureType": "transit.station", "elementType": "geometry", "stylers": [{ "color": "#eeeeee" }] }, { "featureType": "water", "elementType": "geometry", "stylers": [{ "color": "#c9c9c9" }] }, { "featureType": "water", "elementType": "labels.text.fill", "stylers": [{ "color": "#9e9e9e" }] }];

        this.googleMap = new google.maps.Map(mapElement.get(0), {
            zoom: 2,
            center: {
                lat: 0, lng: 0
            },
            streetViewControl: false,
            styles: mapStyles,
            mapTypeControl: false,
            fullscreenControl: false
        });

        var self = this;

        /*
        google.maps.event.addListener(this.googleMap, "zoom_changed", function () {
            self.mapMarkers.forEach(function (marker) {
                marker.redrawLabel(map.drawTriggers.zoomChanged);
            });
        });
        */

        for (var i = 0; i < this.data.length; i++) {
            var markerData = this.data[i];
            var geoId = markerData.geoId;
            if (!this.clusteredData[geoId]) {
                this.clusteredData[geoId] = [markerData];
            } else {
                markerData.showSpacer = true;
                this.clusteredData[geoId].push(markerData);                
            }
        }

        this.dataForCounters = this.data;

        var selectedLocationId = this.selectedLocationId;               

        if (this.divisionFilters.length > 0) {
            this.applyFilters();
        } else if (selectedLocationId > 0) {
            this.mapMarkers.push(this.addMarkerWithDetails(this.getDataWithId(selectedLocationId)));
            this.zoomToFitVisibleMarkers();
            this.showLocationDetails(selectedLocationId);
            this.applyFilters(true);
        }
        else {
            this.applyFilters();
        } 
    },
    /*
    readjustMarkerLabels: function () {
        var shouldReadjust = (this.googleMap.getZoom() >= map.zoomLevelForLabels) && !$(".location-details").length;
        if (!shouldReadjust) {
            return;
        }
        var self = this;
        var visibleMarkers = self.getVisibleMarkers();
        var labels = visibleMarkers.map(function (m) { return m.getLabelElement(); });
        var bounds = labels.map(function (l, idx) {
            var $label = $(l);
            var offset = $label.offset();
            return {
                idx: idx,
                l: offset.left,
                t: offset.top,
                r: offset.left + $label.outerWidth(),
                b: offset.top + $label.outerHeight()
            };
        });
        /// source: https://stackoverflow.com/a/2752387
        var intersects = function (b1, b2) {
            return !(
                b2.l > b1.r ||
                b2.r < b1.l ||
                b2.t > b1.b ||
                b2.b < b1.t
            );
        };
        var clash = [];
        bounds.forEach(function (boundsObj) {
            var clashing = bounds.filter(function (b) {
                return boundsObj.idx != b.idx && intersects(boundsObj, b);
            }).map(function (b) {
                return b.idx;
            });
            if (clashing.length == 0) {
                return;
            }
            clashing.push(boundsObj.idx);
            clash.push(clashing);
        });
        var handled = [];
        clash.forEach(function (c) {
            var alreadyHandled = false;
            c.forEach(function (i) {
                if (handled.indexOf(i) < 0) {
                    handled.push(i);
                } else {
                    alreadyHandled = true;
                }
            });
            if (alreadyHandled) {
                return;
            }
            var adjustedLabels = labels.filter(function (l, idx) { return c.indexOf(idx) >= 0; });
            if (adjustedLabels.length == 1) {
                return;
            } else if (adjustedLabels.length == 2) {
                var $second = $(adjustedLabels[1]);
                var left = parseInt($second.css('left'), 10);
                left -= ($second.outerWidth() + 40);
                $second.css({ 'left': left + 'px' });
            } else if (adjustedLabels.length == 3) {

            }
        });
    },*/

    isMarkerInCluster: function (marker) {

        return this.clusteredMarkers.indexOf(marker.get("id")) >= 0;

    },

    getVisibleMarkers: function () {
        var result = [];

        for (var i = this.mapMarkers.length, bounds = this.googleMap.getBounds(); i--;) {
            if (bounds.contains(this.mapMarkers[i].getPosition())) {
                result.push(this.mapMarkers[i]);
            }
        }

        return result;
    },


    getDataWithGeoId: function (geoId) {
        return this.data.filter(function (d) { return d.geoId == geoId; });
    },

    getDataWithId: function (id) {
        return this.data.find(function (d) { return d.id == id; });
    },

    getMarkerIcon: function (text, fillColor, textColor, outlineColor) {
        if (!!this.markerIconURL) {
            return this.markerIconURL;
        }
        if (!text) text = '•'; //generic map dot
        var iconUrl = "https://chart.googleapis.com/chart?cht=d&chdp=mapsapi&chl=pin%27i\\%27[" + text + "%27-2%27f\\hv%27a\\]h\\]o\\" + fillColor + "%27fC\\" + textColor + "%27tC\\" + outlineColor + "%27eC\\Lauto%27f\\&ext=.png";
        return iconUrl;
    },

    setUpMarkerClusterer: function () {
        var styles = [
            { url: this.clusterIconPath, height: 53, width: 53, anchor: [0,0], textColor: '#7AEA97', fontWeight: 'normal', textSize: 28 }
        ];
        if (!!this.markerClusterer) {
            this.markerClusterer.clearMarkers();
            google.maps.event.clearInstanceListeners(this.markerClusterer);
        }
        this.markerClusterer = new MarkerClusterer(this.googleMap, this.mapMarkers, {
            styles: styles
        });
        var self = this;
        google.maps.event.addListener(this.markerClusterer, 'clusteringend', function (clusterer) {

            var clustered = [];

            var clusters = clusterer.getClusters() || [];
            for (var i = 0; i < clusters.length; i++) {
                var markers = clusters[i].getMarkers() || [];
                if (markers.length > 1) {
                    clustered.push.apply(clustered, markers.map(function (m) { return m.get("id"); }));
                }
            }

            self.clusteredMarkers = clustered;

            self.mapMarkers.forEach(function (marker) {
                marker.redrawLabel(map.drawTriggers.clusteringEnd);
            });
        });
    },

    addMarkerWithDetails: function (details) {

        var data = this.getDataWithGeoId(details.geoId);
        var label = "";
        for (var i = data.length; i--;) {
            var d = data[i];
            label += '<span class="map-marker-label-name">' + d.name + '</span>' + (i > 0 ? '<div class="map-marker-label-spacer">&nbsp;</div>' : '');
        }

        var marker = new google.maps.Marker({
            position: { lat: details.lat, lng: details.lng },
            map: this.googleMap,
            icon: this.getMarkerIcon(null, "000", "fff", "000")
        });
        this.setMarkerId(marker, details.id);
        this.setMarkerDetails(marker, details);
        marker.setLabel(label);

        var self = this;

        marker.addListener("click", function () {
            self.filterMarkers([details.id]);
            self.zoomToFitVisibleMarkers();
            self.showLocationDetails(details.id);
        });
        return marker;
    },

    setMarkerDetails: function (marker, details) {
        marker.set("details", details);
    },

    setMarkerId: function (marker, id) {
        marker.set("id", id);
    },

    getMarkerId: function (marker) {
        return marker.get("id");
    },

    getMarkerDetails: function (marker) {
        return marker.get("details");
    },

    resetView: function () {
        var self = this;

        this.clusteredMarkers = [];

        this.dataForCounters = this.data;

        this.mapMarkers.forEach(function (marker) {
            marker.setMap(null);
        });

        this.mapMarkers = this.data.map(function (d) {
            return self.addMarkerWithDetails(d);
        });

        this.removeDuplicateMarkers();
        this.setUpMarkerClusterer();

        this.googleMap.setCenter({ lat: 0, lng: 0 });
        this.googleMap.setZoom(2);

        this.updateCounters();

    },

    removeDuplicateMarkers: function () {
        var geoIds = [];
        var result = [];
        var self = this;
        this.mapMarkers.forEach(function (m) {
            var data = self.getMarkerDetails(m);
            var geoId = data.geoId;
            var matchingGeoId = geoIds.find(function (g) {
                return g == geoId;
            });
            if (typeof matchingGeoId === "undefined") {
                geoIds.push(geoId);
                result.push(m);
            } else {
                m.removeLabel();
                m.setMap(null);
            }
        });
        this.mapMarkers = result;
    },

    findMarkerWithId: function (id) {
        var self = this;
        return this.mapMarkers.find(function (m) { return self.getMarkerId(m) == id; });
    },

    zoomToFitVisibleMarkers: function () {

        if (this.mapMarkers.length == 0) {
            return;
        }

        var getMarkerLat = function (marker) { return marker.getPosition().lat(); };
        var getMarkerLng = function (marker) { return marker.getPosition().lng(); };

        if (this.mapMarkers.length == 1) {
            var marker = this.mapMarkers[0];
            this.googleMap.setCenter({ lat: getMarkerLat(marker), lng: getMarkerLng(marker) });
            this.googleMap.setZoom(14);
        } else {
            var east = Math.max.apply(null, this.mapMarkers.map(getMarkerLng));
            var west = Math.min.apply(null, this.mapMarkers.map(getMarkerLng));
            var north = Math.max.apply(null, this.mapMarkers.map(getMarkerLat));
            var south = Math.min.apply(null, this.mapMarkers.map(getMarkerLat));

            this.googleMap.fitBounds({ east: east, west: west, north: north, south: south });
        }

    },

    showLocationDetails: function (id) {        
        var marker = this.findMarkerWithId(id);
        var details = this.getMarkerDetails(marker);
        var geoId = details.geoId;
        var sites = this.getDataWithGeoId(geoId);
        var locations = { locations: sites };
        var detailsTemplate = $("#location-details-template").html();
        var detailsElement = Mustache.render(detailsTemplate, locations);
        $("#map").append(detailsElement);
        if (sites.length > 1) {
            $("#map").find(".location-details").addClass("location-scroll");
        }
        marker.redrawLabel("show location details");
        contact.selectSite.call(contact, id);
    },

    closeLocationDetails: function (element) {
        $(element).remove();
        this.selectedLocationId = -1;
        this.applyFilters();
    },

    handleFilterChange: function () {
        var selectedDivisions = [];
        var selectedTypes = [];
        var selectedRegions = [];

        var processCheckedValues = function (selector, targetArray) {
            $(selector).each(function (idx, el) {
                var input = $(el);
                if (!!input.prop("checked")) {
                    targetArray.push(parseInt(input.val()));
                }
            });
        };

        processCheckedValues("input[name=map-division]", selectedDivisions);
        processCheckedValues("input[name=map-locationType]", selectedTypes);
        processCheckedValues("input[name=map-region]", selectedRegions);

        this.divisionFilters = selectedDivisions;
        this.locationTypeFilters = selectedTypes;
        this.regionFilters = selectedRegions;
        this.applyFilters();
    },

    applyFilters: function (doNotResetView) {
        var self = this;

        var divisionComparator = function (location, division) {
            return location.divisions.indexOf(division) >= 0;
        };
        var typeComparator = function (location, type) {
            return location.type == type;
        };
        var regionComparator = function (location, region) {
            return location.region == region;
        };


        var filterByDivisions = function (source, divisions) {
            if (divisions.length == 0) {
                return source;
            }
            return source.filter(function (location) {
                return divisions.filter(function (div) {
                    return location.divisions.indexOf(div) >= 0;
                }).length > 0;
            });
        };
        var filterByTypes = function (source, types) {
            if (types.length == 0) {
                return source;
            }
            return source.filter(function (location) {
                return types.indexOf(location.type) >= 0;
            });
        };
        var filterByRegions = function (source, regions) {
            if (regions.length == 0) {
                return source;
            }
            return source.filter(function (location) {
                return regions.indexOf(location.region) >= 0;
            });
        };

        var filteredData = [];

        var filteredByDivisions = filterByDivisions(this.data, this.divisionFilters);
        var filteredByTypes = filterByTypes(this.data, this.locationTypeFilters);
        var filteredByRegions = filterByRegions(this.data, this.regionFilters);

        filteredData = filteredByDivisions.slice();
        filteredData = filterByTypes(filteredData, this.locationTypeFilters);

        this.dataForCounters = filteredData.slice();

        filteredData = filterByRegions(filteredData, this.regionFilters);

        this.updateCheckboxes("input[name=map-division]", filterByRegions(filteredByTypes, this.regionFilters), divisionComparator);
        this.updateCheckboxes("input[name=map-locationType]", filterByRegions(filteredByDivisions, this.regionFilters), typeComparator);
        this.updateCheckboxes("input[name=map-region]", filterByDivisions(filteredByTypes, this.divisionFilters), regionComparator);

        if (this.divisionFilters.length == 0 && this.locationTypeFilters.length == 0 && this.regionFilters.length == 0) {
            if (!doNotResetView) {
                this.resetView();
            }
            return;
        }

        this.filterMarkers(filteredData.map(function (d) { return d.id; }));
        this.zoomToFitVisibleMarkers();
        this.updateCounters();
    },

    updateCheckboxes: function (selector, dataSource, comparator) {
        $(selector).each(function (idx, el) {
            var input = $(el);
            var value = parseInt(input.val());
            var count = dataSource.filter(function (d) {
                return comparator(d, value);
            }).length;
            input.prop("disabled", count == 0);
        });
    },
 
    filterMarkers: function (ids) {

        if (!ids) {
            return;
        }

        var self = this;

        var idsToAdd = ids.slice();

        // remove markers that don't match
        this.mapMarkers.forEach(function (m) {
            var markerId = self.getMarkerId(m);
            if (ids.indexOf(markerId) < 0) {
                m.setMap(null);
            } else {
                // the marker is already on the map - no need to add it, so let's remove of from the list of new markers
                var idx = idsToAdd.indexOf(markerId);
                if (idx >= 0) {
                    idsToAdd.splice(idx, 1);
                }
            }
        });

        // if we're already showing some matching markers, keep them in the markers array, but remove others
        this.mapMarkers = this.mapMarkers.filter(function (m) {
            return ids.indexOf(self.getMarkerId(m)) >= 0;
        });

        idsToAdd.forEach(function (id) {
            self.mapMarkers.push(self.addMarkerWithDetails(self.getDataWithId(id)));
        });

        this.removeDuplicateMarkers();
        this.setUpMarkerClusterer();

    },

    updateCounters: function () {
        var self = this;
        $("input[name=map-region]").each(function (idx, el) {
            var input = $(el);
            var region = parseInt(input.val());
            var count = self.dataForCounters.filter(function (d) {
                return d.region == region;
            }).length;
            input.siblings().find("span.count").text("(" + count + ")");
        });
    }

};

map.location = function (data) {
    this.id = data.id || 0;
    this.url = data.url || "";
    this.name = data.name || "";
    this.address = data.address || "";
    this.email = data.email || "";
    this.phone = data.phone || "";
    this.lat = data.lat || 0.0;
    this.lng = data.lng || 0.0;
    this.geoId = data.geoId || "";
    this.externalLink = data.externalLink || null;
    this.externalLinkText = data.externalLinkText || null;
    this.divisions = data.divisions || [];
    this.showInvoiceInformationLink = data.showInvoiceInformationLink || false;
    this.showSpacer = false;
    if (data.hasOwnProperty("type")) {
        this.type = data.type;
    } else {
        this.type = -1;
    }
    if (data.hasOwnProperty("region")) {
        this.region = data.region;
    } else {
        this.region = -1;
    }
};

document.addEventListener("DOMContentLoaded", function () {
    $("input[name=map-division]").on("change", function () {
        map.handleFilterChange("division");
    });
    $("input[name=map-locationType]").on("change", function () {
        map.handleFilterChange("locationType");
    });
    $("input[name=map-region]").on("change", function () {
        map.handleFilterChange("region");
    });
    var updateToggleControl = function (showExpandOrCollapse) {
        if (showExpandOrCollapse) {
			$(".map-filters-toggle").removeClass("open");
	        $(".map-filters-toggle").addClass("closed");
		} else {
	        $(".map-filters-toggle").addClass("open");
	        $(".map-filters-toggle").removeClass("closed");
        }
    }
    $(".map-filters-toggle").on("click", function () {
        var filtersContainer = $(".map-filters");
        var isVisible = filtersContainer.is(":visible");
        updateToggleControl(isVisible);
        filtersContainer.slideToggle("fast", function () {
            isVisible = $(this).is(":visible");
            updateToggleControl(!isVisible);
        });
    });
});
// https://tc39.github.io/ecma262/#sec-array.prototype.find
if (!Array.prototype.find) {
    Object.defineProperty(Array.prototype, 'find', {
        value: function (predicate) {
            // 1. Let O be ? ToObject(this value).
            if (this == null) {
                throw new TypeError('"this" is null or not defined');
            }

            var o = Object(this);

            // 2. Let len be ? ToLength(? Get(O, "length")).
            var len = o.length >>> 0;

            // 3. If IsCallable(predicate) is false, throw a TypeError exception.
            if (typeof predicate !== 'function') {
                throw new TypeError('predicate must be a function');
            }

            // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
            var thisArg = arguments[1];

            // 5. Let k be 0.
            var k = 0;

            // 6. Repeat, while k < len
            while (k < len) {
                // a. Let Pk be ! ToString(k).
                // b. Let kValue be ? Get(O, Pk).
                // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
                // d. If testResult is true, return kValue.
                var kValue = o[k];
                if (predicate.call(thisArg, kValue, k, o)) {
                    return kValue;
                }
                // e. Increase k by 1.
                k++;
            }

            // 7. Return undefined.
            return undefined;
        },
        configurable: true,
        writable: true
    });
}
document.addEventListener('DOMContentLoaded', function () {
    var siteHeader = document.querySelector('.site-header');

    var prevScrollpos = window.pageYOffset;
    window.onscroll = function() {
        var currentScrollPos = window.pageYOffset;
        if (currentScrollPos > 100) {
            if (prevScrollpos > currentScrollPos) {
                siteHeader.classList.remove('site-header-off');
            } else {
                siteHeader.classList.add('site-header-off');
            }
            prevScrollpos = currentScrollPos;
        } else {
            siteHeader.classList.remove('site-header-off');
        }
    }
});
"use strict";

document.addEventListener('DOMContentLoaded', function() {
    var subscribeForms = $('.investor-info-subscription-block');

    if (subscribeForms.length > 0) {
        subscribe.initialize();
    }
});

var subscribe = {
    initialize: function () {
        var self = this;
        var hasErrors = false;
        var languages = $('[data-languages] input[type="checkbox"]');
        var languagesErrorMsg = $('.languages.validation-error');
        var informationTypes = $('[data-information-types] input[type="checkbox"]');
        var informationTypesErrorMsg = $('.information-types.validation-error');
        var requiredFields = $('.cision-form input[type="text"][aria-required="true"]');

        $('[data-languages] input[type="checkbox"]').on('change', function () {
            if ($(this).prop('checked') === true) {
                self.handleValidation(true, languagesErrorMsg);
            }
        });
        $('[data-information-types] input[type="checkbox"]').on('change', function () {
            if ($(this).prop('checked') === true) {
                self.handleValidation(true, informationTypesErrorMsg);
            }
        });

        $('.cision-form').submit(function (e) {
            if (languages.length > 0) {
                var languageChecked = false;
                languages.each(function (index, elem) {
                    if ($(elem).prop('checked') === true) {
                        languages = true;
                    }
                });
                if (languages !== true) {
                    hasErrors = true;
                    self.handleValidation(false, languagesErrorMsg);
                } else {
                    self.handleValidation(true, languagesErrorMsg);
                }
            } 
            if (informationTypes.length > 0) {
                var infoTypeChecked = false;
                informationTypes.each(function (index, elem) {
                    if ($(elem).prop('checked') === true) {
                        infoTypeChecked = true;
                    }
                });
                if (infoTypeChecked !== true) {
                    hasErrors = true;
                    self.handleValidation(false, informationTypesErrorMsg);
                } else {
                    self.handleValidation(true, informationTypesErrorMsg);
                }
            }
            if (requiredFields.length > 0) {
                requiredFields.each(function (index, elem) {
                    var el = $(elem);
                    var elVal = el.val();

                    if (el.hasClass('form-control-email')) {
                        if ($.trim(elVal).length === 0 || self.validateEmail(elVal) || elVal === '') {
                            hasErrors = true;
                            self.handleValidation(false, el.next('.validation-error'));
                        }
                    } else if (elVal === '') {
                        hasErrors = true;
                        self.handleValidation(false, el.next('.validation-error'));
                    } else {
                        self.handleValidation(true, el.next('.validation-error'));
                    }
                });
            } else {
                $('.validation-error').addClass('hidden');
                $('.contact-form button').prop('disabled', true);
            }

            if (hasErrors) {
                e.preventDefault();
                hasErrors = false;
            }
        });

        $('.cision-form input[type=text]').change(function () {
            $(this).next('.validation-error').addClass('hidden');
        });
    },

    validateEmail: function (email) {
        var reg = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
        if (!reg.test(email)) {
            return true;
        } else {
            return false;
        }
    },

    handleValidation: function (isValid, elem) {
        if (isValid) {
            elem.addClass('hidden');
            elem.attr('aria-hidden', 'true');
        } else {
            elem.removeClass('hidden');
            elem.attr('aria-hidden', 'false');
        }
    }
};
(function() {
    function setAttributes(el, attrs) {
        for (var key in attrs) {
            el.setAttribute(key, attrs[key]);
        }
    }

    var expandCounter = 0;
    var expandTables = document.querySelectorAll('[data-expand-table]');
    for (var i = 0; i < expandTables.length; i++) {
        var target = expandTables[i];
        var toggler = target.previousSibling;
        if (toggler.tagName === 'DIV' && toggler.className === 'section-heading') {
            setAttributes(target, {
                'id': 'expandTable' + expandCounter,
                'data-expand-table': expandCounter
            });
            setAttributes(toggler, {
                'role': 'button',
                'aria-controls': 'expandTable' + expandCounter,
                'aria-expanded': 'false',
                'tabindex': '0',
                'data-expandable': expandCounter,
                'class': 'section-heading section-heading-expand'
            });
            expandCounter++;
        }
    }

    var dataTogglers = document.querySelectorAll('[data-expandable]');
    var expandedClass = 'expanded';
    var expandableClass = 'elk-table-hidden';
    function toggleExpandable(toggler) {
        var togglerValue = toggler.getAttribute('data-expandable');
        var expandable = document.querySelector('[data-expand-table="' + togglerValue + '"]');
        expandable.classList.add(expandableClass);
        var doToggle = function() {
            if (expandable.classList.contains(expandableClass)) {
                toggler.classList.add(expandedClass);
                setAttributes(toggler, {
                    'aria-expanded': 'true'
                });
                expandable.classList.remove(expandableClass);
            } else {
                toggler.classList.remove(expandedClass);
                setAttributes(toggler, {
                    'aria-expanded': 'false'
                });
                expandable.classList.add(expandableClass);
            }
        };
        toggler.onclick = function() {
            doToggle();
        };
        toggler.onkeyup = function(e) {
            if (e.keyCode === 13) {
                doToggle();
            }
        };
    }

    for (var j = 0; j < dataTogglers.length; j++) {
        var toggler = dataTogglers[j];
        toggleExpandable(toggler);
    }
}());
"use strict";

document.addEventListener("DOMContentLoaded", function (event) {

    $(".topic-section").each(function (index, el) {
        var $section = $(el);
        var $indexElement = $section.find(".topic-section-index");
        var $contents = $section.find(".topic-section-contents").children();
        $contents.each(function (i, e) {
            if (e.tagName.match(/H[1-5]/)) {
                var $heading = $(e);
                var anchorName = "topic-" + index + "-" + i;
                var $indexItem = $('<li/>');
                var $indexItemLink = $('<a/>').attr("href", "#" + anchorName).html($heading.text());
                $indexItem.append($indexItemLink);
                $indexElement.append($indexItem);
                var $anchorElement = $('<a/>').attr("name", anchorName);
                $heading.prepend($anchorElement);
            }
        });
    });

});
'use strict';

document.addEventListener("DOMContentLoaded", function (event) {

    if (!Array.prototype['forEach']) {
        Array.prototype.forEach = function(callback, thisArg) {
            if (this == null) { throw new TypeError('Array.prototype.forEach called on null or undefined'); }
            var T, k, O = Object(this), len = O.length >>> 0;
            if (typeof callback !== "function") { throw new TypeError(callback + ' is not a function'); }
            if (arguments.length > 1) { T = thisArg; }
            k = 0; while (k < len) { var kValue; if (k in O) { kValue = O[k]; callback.call(T, kValue, k, O); } k++; }
        };
    }

    var vacancyFilterSection = document.querySelectorAll('[data-vacancy-filters]');
    var vacancyFilters = document.querySelectorAll('[data-vacancy-filters] select');
    var locationSelect = document.querySelector('[data-vacancy-filter="location"]');
    var divisionSelect = document.querySelector('[data-vacancy-filter="division"]');
    var regionSelect = document.querySelector('[data-vacancy-filter="region"]');
    var vacancyResults = document.querySelector('[data-vacancy-list-results]');
    
    function doFilters() {
        var locationVal = locationSelect ? locationSelect.options[locationSelect.selectedIndex].value : 'All';
        var divisionVal = divisionSelect ? divisionSelect.options[divisionSelect.selectedIndex].value : 'All';
        var regionVal = regionSelect ? regionSelect.options[regionSelect.selectedIndex].value : 'All';

        var filteredData = vacancyItems.filter(function (e) {
            return (locationVal === 'All' || !locationVal || e.location === locationVal) && (divisionVal === 'All' || !divisionVal || e.division === divisionVal) && (regionVal === 'All' || !regionVal || e.region === regionVal);
        });

        vacancyResults.innerHTML = '';

        filteredData.forEach(function (el) {
            var html = '';
            el.url !== undefined ? (html += '<a class="row vacancy-list-item" href="' + el.url + '"') : '<span class="vacancy-list-item"';
            el.location !== undefined && (html += ' data-location="' + el.location + '"');
            el.division !== undefined && (html += ' data-division="' + el.division + '"');
            el.region !== undefined && (html += ' data-region="' + el.region + '"');
            html += '>';
            html += '<div class="col-md-12 vacancy-list-header">' + (el.title !== undefined ? '<span aria-label="Position">' + el.title + '</span>' : '') + '</div>';
            html += '<div class="col-md-12 vacancy-list-details">' + (el.location ? '<span aria-label="Location">' + el.location + '</span>' : '');
            html += (el.division !== undefined && divisionSelect ? '<span aria-label="Division">' + el.division + '</span>' : '');
            html += (el.date !== undefined ? '<span aria-label="Application deadline">' + el.date + '</span>' : '') + '</div>';
            html += (el.url !== undefined ? '</a>' : '</span>');
            vacancyResults.innerHTML += html;
        });

        if (vacancyResults.innerHTML === '') {
            vacancyResults.innerHTML += '<div class="vacancy-list-item">' + vacancyResults.dataset.noVacanciesFound + '</div>';
        }
    }

    if (vacancyFilterSection !== null && vacancyFilters.length > 0) {
        doFilters();

        vacancyFilters.forEach(function (select) {
            select.addEventListener('change', function () {
                doFilters(vacancyItems);
            }, false);
        });
    }
});